.App {
  text-align: center;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1vmin);
  color: black;
}

code {
  text-align: left;
}

.swagger-ui {
  text-align: left;
}

.apitoken-container {
  margin-top: 1em;
}

.apitoken-box {
  margin-right: 0.5em;
  border: dashed var(--bs-code-color);
  border-width: 0.05em;
  font-size: 1.2em;
}

.selected-tenant {
  background-color: #0d6dfdb2;
}

.row-loading-spinner {
  margin: 1em;
}

.implementation-pills {
  justify-content: center;
  margin-top: 1em;
}

.logo-svg {
  stroke: rgb(33 31 38);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.logo {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.logo img {
  width: 10rem;
}

.header {
  margin-bottom: 1rem;
}

.muted-text {
  font-weight: 200;
}

.connected {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.learn-more {
  margin-top: 10%;
  border-top: 1px solid;
  font-weight: 100;
  font-size: smaller;
  padding-top: 1%;
}